import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "./Container";
import MusicCard from "./MusicCard";

function MusicCardContainer() {
  const [musicDB, setMusicDB] = useState([]);

  useEffect(() => {
    const fetchMusicDB = async () => {
      try {
        const response = await axios.get(
          "https://5.75.147.133/api/nowplaying"
        );
        const stationDataArray = response.data;

        // Set all properties of the objects in musicDB array
        const updatedMusicDB = stationDataArray.map((stationData) => ({
          id: stationData.station.id,
          name: stationData.station.name,
          author_name: stationData.station.name,
          img: "", // Initially set to empty string
          lang: "ENGLISH",
          timesPlayed: 0,
          type: "electronic",
          musicName: stationData.station.listen_url,
          attribution: {
            song: "",
            musicBy: "",
            download: stationData.station.listen_url,
            stream: stationData.station.listen_url,
          },
        }));

        // Update only the img property using now_playing data
        updatedMusicDB.forEach((item, index) => {
          item.img = stationDataArray[index].now_playing.song.art;
        });

        setMusicDB(updatedMusicDB);
      } catch (error) {
        console.error("Error fetching station data:", error);
      }
    };

    // Fetch music data initially
    fetchMusicDB();

    // Poll the server at regular intervals to update music data
    const intervalId = setInterval(fetchMusicDB, 1000000);

    // Cleanup function to clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []); // Run only once on component mount

  return (
    <Container>
      <div className={"music-card-container"}>
        {musicDB.length === 0 ? (
          <p>Loading...</p> // Show loading indicator if musicDB is empty
        ) : (
          musicDB.map((item) => <MusicCard key={item.id} music={item} />)
        )}
      </div>
    </Container>
  );
}

export default MusicCardContainer;
