// Define action types
export const SET_PLAYLIST = 'SET_PLAYLIST';
export const SET_CURR_PLAYING = 'SET_CURR_PLAYING';
export const SET_BANNER_OPEN = 'SET_BANNER_OPEN';
export const INC_TIMES_PLAYED = 'INC_TIMES_PLAYED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_MUSIC_LIST = 'SET_MUSIC_LIST';

// Action creators
export const setPlaylist = (playlist) => ({
  type: SET_PLAYLIST,
  payload: playlist,
});

export const setCurrPlaying = (song) => ({
  type: SET_CURR_PLAYING,
  payload: song,
});

export const setBannerOpen = (isOpen) => ({
  type: SET_BANNER_OPEN,
  payload: isOpen,
});

export const incTimesPlayed = (index) => ({
  type: INC_TIMES_PLAYED,
  payload: index,
});

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: query,
});

export const setMusicList = (list) => ({
  type: SET_MUSIC_LIST,
  payload: list,
});
