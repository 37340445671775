import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeContext, themes } from "../api/Theme";
import './App.scss';
import Home from "../components/Pages/Home";
import Login from "../components/Pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { setPlaylist } from '../reducers/musicActions';
// Assuming this is in '../db/music.js'
import fetchAndSetPlaylist from '../db/music';



const App = () => {
    const { language } = useSelector(state => state.musicReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchPlaylist = async () => {
            try {
                const musicDB = await fetchAndSetPlaylist(); // Fetch music data using fetchAndSetPlaylist function
                if (language === null || language.includes("any")) {
                    dispatch(setPlaylist(musicDB));
                } else if (language.includes('hindi')) {
                    alert("No tracks available");
                } else {
                    dispatch(setPlaylist(musicDB));
                }
            } catch (error) {
                console.error('Error fetching music data:', error);
            }
        };

        fetchPlaylist(); // Call fetchPlaylist function
    }, [dispatch, language]);

    return (
        <ThemeContext.Provider value={themes.dark}>
            <>
                <Router>
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/home" component={Home} />
                    </Switch>
                </Router>
            </>
        </ThemeContext.Provider>
    );
}

export default App;
