import React from 'react';
import '../assets/scss/Developer.scss';
import {IconButton} from "@material-ui/core";
import AvatarImage from "../assets/img/avatar.jpg";
import {Facebook, Instagram, LinkedIn, Portrait, Twitter} from "@material-ui/icons";
import HeadPhone from '../assets/img/logo.png';
const Developer = () => {
    return (
        <div className={"Developer"}>
            <h3 className={"Developer-head"}>About Ardi Radio</h3>
            <div className="Developer-profile">
                <div className="Developer-profileCard">
                    <img src={HeadPhone} alt="Profile"/>
                    <div className={"Card-details"}>
                        <h3>Ardi Radio</h3>
                        {/* <p>Full Stack developer</p>
                        <p>Competitive Coder</p> */}
                    </div>
                </div>
                <div className="Developer-profileDetails">
                    <p>About ArdiRadio <br></br>

At ArdiRadio, we are passionate about delivering high-quality online radio services to our listeners worldwide. Our platform, developed by Mela Technologies, offers a seamless and immersive experience, allowing users to explore a diverse range of music, news, podcasts, and more.</p>
<p>Our Services

<br></br>

<ul>
            <li>Live Radio Streaming: Access to live radio stations from around the globe, covering various genres including pop, rock, classical, jazz, and more.</li>
            <li>On-Demand Content: Enjoy on-demand access to a vast library of podcasts, interviews, talk shows, and other audio content, allowing users to listen at their convenience.</li>
            <li>Customized Playlists: Create personalized playlists based on your favorite songs, artists, or genres, ensuring a tailored listening experience.</li>
            <li>User-Friendly Interface: Our intuitive and user-friendly interface makes it easy to navigate through the app, discover new content, and manage your preferences effortlessly.</li>
        </ul>
</p>
                    <div className="Card-btn">
                        <IconButton target={"_blank"}  href={"https://www.facebook.com"} title={"ArdiRadio"}>
                            <Facebook/>
                        </IconButton>
                        <IconButton target={"_blank"} href={"https://twitter.com"}  title={"ArdiRadio"}>
                            <Twitter/>
                        </IconButton>
                        <IconButton target={"_blank"} href={"https://www.linkedin.com"}  title={"ArdiRadio"}>
                            <LinkedIn/>
                        </IconButton>
                        <IconButton target={"_blank"} href={"https://www.instagram.com"}  title={"ArdiRadio"}>
                            <Instagram/>
                        </IconButton>
                        
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Developer;