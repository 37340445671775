import React, { useState, useEffect } from "react";
import axios from 'axios';
import store from '../reducers/store';
import { setPlaylist } from '../reducers/musicActions';

const fetchStationData = async () => {
    try {
        const response = await axios.get(
            "https://5.75.147.133/api/nowplaying"
        );
        const stationDataArray = response.data;

        // Set all properties of the objects in musicDB array
        const updatedMusicDB = stationDataArray.map((stationData) => ({
            id: stationData.station.id,
            name: stationData.station.name,
            author_name: stationData.station.name,
            img: "", // Initially set to empty string
            lang: "ENGLISH",
            timesPlayed: 0,
            type: "electronic",
            musicName: stationData.station.listen_url,
            attribution: {
                song: "",
                musicBy: "",
                download: stationData.station.listen_url,
                stream: stationData.station.listen_url,
            },
        }));

        // Update only the img property using now_playing data
        updatedMusicDB.forEach((item, index) => {
            item.img = stationDataArray[index].now_playing.song.art;
        });

        return updatedMusicDB;
    } catch (error) {
        console.error("Error fetching station data:", error);
        throw error; // Rethrow the error to handle it in the caller function
    }
};

const fetchAndSetPlaylist = async () => {
    try {
        const musicDB = await fetchStationData();
        store.dispatch(setPlaylist(musicDB));
    } catch (error) {
        console.error('Error fetching music data:', error);
    }
};

export default fetchAndSetPlaylist;
